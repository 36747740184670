import React, { Component } from 'react';
import history from './history';

import SignIn from './SignIn';
import Dashboard from './Dashboard';

import { Router, Route, Redirect, Switch } from 'react-router-dom';

import { withI18n } from 'react-i18next';

import './i18n';

import CircularProgress from '@material-ui/core/CircularProgress';

import { withStyles } from '@material-ui/core/styles';
import { getParameter } from './request';

// Application context (i.e. application model, i18n labels, selected language, current user, GraphQL endpoint, ...).
export const AppContext = React.createContext();

var keyboardLocked = false;

document.onkeydown = function(e) {
	if (keyboardLocked) {
		console.log("Keyboard locked!");
	}
	return !keyboardLocked;
}

const styles = theme => ({
	circularProgress: {
		position: "fixed",
		left: "50%",
		top: "50%",
		marginTop: "-20px",
		marginLeft: "-20px",
		zIndex: 20001,
		color: "inherit",
	},
	circleIndeterminate: {
		animation: 'mui-progress-circular-dash 0s ease-in-out infinite',
		// Some default value that looks fine waiting for the animation to kicks in.
		strokeDasharray: '80px, 200px',
		strokeDashoffset: '0px' // Add the unit to fix a Edge 16 and below bug.
	},
});

class App extends Component {
	
	// Constructor is called twice in development mode in Chrome (it is the expected behavior when StrictMode)
	// https://reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects
	constructor(props) {
		super(props);
		//console.log(">> App.constructor");
		
		function parseUrl(url) {
			var m = url.match(/^(([^:\/?#]+:)?(?:\/\/((?:([^\/?#:]*):([^\/?#:]*)@)?([^\/?#:]*)(?::([^\/?#:]*))?)))?([^?#]*)(\?[^#]*)?(#.*)?$/);
			var r = {
				hash: m[10] || "",
				host: m[3] || "",
				hostname: m[6] || "",
				href: m[0] || "",
				origin: m[1] || "",
				pathname: m[8] || (m[1] ? "/" : ""),
				port: m[7] || "",
				protocol: m[2] || "",
				search: m[9] || "",
				username: m[4] || "",
				password: m[5] || ""
			};
			if (r.protocol.length == 2) {
				r.protocol = "file:///" + r.protocol.toUpperCase();
				r.origin = r.protocol + "//" + r.host;
			}
			r.href = r.origin + r.pathname + r.search + r.hash;
			return m && r;
		};
		
		var baseUrl = document.location.protocol + "//" + document.location.host;
		//baseUrl += (document.location.pathname.indexOf("/login") > 0 ? document.location.pathname.substr(0, document.location.pathname.indexOf('/login')) : '');
		//baseUrl += (document.location.pathname.indexOf("/admin") > 0 ? document.location.pathname.substr(0, document.location.pathname.indexOf('/admin')) : '');
		
		var parsedBaseUrl = parseUrl(baseUrl);
		
		this.state = {
			
			// State for different entities
			state: {},
			
			//baseUrl: "https://localhost:8443",
			baseUrl: baseUrl,
			
			disableHeader: (getParameter("disableHeader", document.location.search) == "true"),
			disableMenu: (getParameter("disableMenu", document.location.search) == "true"),
			
			loolEndpoint: parsedBaseUrl.protocol + "//" + parsedBaseUrl.hostname + ":9980",
			
			accessToken: null,
			
			open: false,
			handleDrawerOpen: () => {
				this.setState({ open: true });
			},
			handleDrawerClose: () => {
				this.setState({ open: false });
			},
			
			activityIndicatorVisible: false,
			showActivityIndicator: () => {
				keyboardLocked = true;
				this.setState({
					activityIndicatorVisible: true
				});
			},
			hideActivityIndicator: () => {
				keyboardLocked = false;
				this.setState({
					activityIndicatorVisible: false
				});
			},
			
			setState: (state) => {
				this.setState(state);
			}
		};
		
		this.handleOverlayClick = this.handleOverlayClick.bind(this);
		
	}
	
	handleOverlayClick(event) {
		console.log("Overlay clicked!");
	}
	
	/*
	componentWillMount() {
		// En principio voy a prescindir de guardar el estado en local, si refresco la página envío el usuario al login
		//    para que vuelva a cargar su modelo, el modelo local, las etiquetas, el token de acceso, ....
		// No recuerdo por qué hacía falta guardar el contexto en local, creo que había un problema con la navegación que ya no se da.
		// Además, el método componentWillMount está deprecado.
		// Lo dejaré comentado durante un tiempo para verificar que la aplicación funciona correctamente.
		// Reload context from local storage
		var storedContext = localStorage.getItem("context");
		if (storedContext != null) {
			this.setState(JSON.parse(storedContext));
		}
	}
	*/
	
	render() {
		const { classes } = this.props;
		
		/*
	    history.listen((location, action) => {
	    	if (this.state != null) {
	    		this.setState({ open: false });
	    	}
	    });
	    */
		
		return (
			<AppContext.Provider value={this.state}>
				<AppContext.Consumer> 
					{(context) => {
						// Leeer el otro comentario donde se lee del almacenamiento local
						//console.log(context);
						//localStorage.setItem("context", JSON.stringify(context));
					}}
				</AppContext.Consumer>
				
				<Router history={history}>
					<Switch>
						<Route path="/" exact strict render={props => <Redirect to="/login"/>}/>
						<Route path="/tools/:toolName" exact strict render={props => <Dashboard theme={this.props.theme}/>}/>
						<Route path="/dashboard/:id/:name" exact strict render={props => <Dashboard theme={this.props.theme}/>}/>
						<Route path="/login" exact strict render={props => <SignIn onSubmit={this.handleLogin} theme={this.props.theme}/>}/>
						<Route path="/admin" exact strict render={props => <Dashboard theme={this.props.theme}/>}/>
						<Route path="/admin/:entityName" exact strict render={props => <Dashboard theme={this.props.theme}/>}/>
						<Route path="/admin/:entityName/external" exact strict render={props => <Dashboard theme={this.props.theme}/>}/>
						<Route path="/admin/:entityName/new" exact strict render={props => <Dashboard theme={this.props.theme}/>}/>
						<Route path="/admin/:entityName/new/:preselectedAttribute/:preselectedValue" exact strict render={props => <Dashboard theme={this.props.theme}/>}/>
						<Route path="/admin/:entityName/:entityId/view" exact strict render={props => <Dashboard theme={this.props.theme}/>}/>
						<Route path="/admin/:entityName/:entityId/edit" exact strict render={props => <Dashboard theme={this.props.theme}/>}/>
						<Route path="/admin/:entityName/:entityId/:attributeName/edit" exact strict render={props => <Dashboard theme={this.props.theme}/>}/>
						<Route render={props => <Redirect to="/"/>}/>
					</Switch>
				</Router>
				<AppContext.Consumer> 
					{(context) => {return context.activityIndicatorVisible && (
						<div style={{position: "fixed", left: 0, right: 0, top: 0, bottom: 0, zIndex: 20000, backgroundColor: "rgba(0, 0, 0, 0)"}} onClick={this.handleOverlayClick}>
							<CircularProgress classes={{circleIndeterminate: classes.circleIndeterminate}} className={classes.circularProgress}></CircularProgress>
						</div>
					)}}
				</AppContext.Consumer>
			</AppContext.Provider>
		);
	}
}

// Each time context changes it is assigned to props.context using this HOC (Higher-order component) wrapper.
export function withContext(Component) {
	return function WithContextComponent(props) {
		return (
			<AppContext.Consumer>
				{ context => <Component {...props} context={context}/> }
			</AppContext.Consumer>
		);
	};
}

export default withStyles(styles)(withContext(withI18n()(App)));
