import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Input from '@material-ui/core/Input';
import Hidden from '@material-ui/core/Hidden';
import RefreshIcon from '@material-ui/icons/Refresh';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ClearIcon from '@material-ui/icons/Clear';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AdminIcon from '@material-ui/icons/Build';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@material-ui/icons/HelpOutline';
import UserIcon from '@material-ui/icons/AccountCircle';
import { AppContext, withContext } from './App';
import { Redirect } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withI18n } from 'react-i18next';
import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';
import { withRouter } from 'react-router';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//import VirtualAnalyst from './VirtualAnalyst';
import EntityList from './EntityList';
import EntityView from './EntityView';
import AdminTools from './AdminTools';
import DashboardTool from './DashboardTool';
import history from './history';
import { localModel } from './localModel';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';

import 'material-design-icons/iconfont/material-icons.css';
import { refreshPersonalization, refreshModels } from './refresh';
import i18n from 'i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

const drawerWidth = 260;

const styles = theme => ({
	container: {
		display: "flex",
		flexDirection: "column",
		minHeight: "100vh",
	},
	root: {
		padding: 0,
		display: "flex",
		flexGrow: 1,
	},
	logViewer: {
		padding: 0,
		position: "relative",
		width: '100%',
		margin: 0,
		[theme.breakpoints.up('lg')]: {
			marginLeft: drawerWidth,
			width: `calc(100% - ${drawerWidth}px)`,
		},
	},
	logViewerDisabledMenu: {
		padding: 0,
		position: "relative",
		width: '100%',
		margin: 0,
	},
	logContainer: {
		display: "flex",
		flexDirection: "column",
	},
	logToolbar: {
		display: "flex",
		flexDirection: "row",
	},
	pre: {
	    whiteSpace: "pre-wrap",
	    wordWrap: "break-word",
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
	iframe: {
		width: '100%',
		height: '100%',
		display: 'block',
		border: 'none',
	},
	button: {
	    marginRight: theme.spacing.unit * 2,
	},
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		marginLeft: drawerWidth,
		[theme.breakpoints.up('lg')]: {
			width: `calc(100% - ${drawerWidth}px)`,
		},
	},
	appBarDisabledMenu: {
		zIndex: theme.zIndex.drawer + 1,
		marginLeft: 0,
	},
	menuButton: {
		marginLeft: 12,
	},
	menuGroup: {
		textAlign: "left",
		paddingLeft: "20px",
		paddingTop: "20px",
		fontWeight: "bold",
		marginBottom: "10px",
	},
	highlightedMenuGroup: {
		textAlign: "left",
		marginTop: "10px",
		paddingTop: "20px",
		fontWeight: "bold",
		marginLeft: "10px",
		paddingLeft: "8px",
		borderLeft: "2px",
		borderRight: 0,
		borderTop: 0,
		borderBottom: 0,
		borderColor: theme.palette.primary.main,
		borderStyle: "solid",
	},
	highlightedMenuGroupCaption: {
		textAlign: "left",
		marginBottom: "10px",
		paddingBottom: "10px",
		marginLeft: "10px",
		paddingLeft: "8px",
		borderLeft: "2px",
		borderRight: 0,
		borderTop: 0,
		borderBottom: 0,
		borderColor: theme.palette.primary.main,
		borderStyle: "solid",
	},
	menuGroupClose: {
		[theme.breakpoints.down('md')]: {
			display: "none",
		},
	},
	spacer: {
		flex: "1 1 100%",
	},
	title: {
		flexGrow: 1,
		paddingLeft: 10,
		marginLeft: 20,
	},
	drawerPaper: {
		width: drawerWidth,
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		'&:hover': {
			overflowY: "auto",
		}
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		padding: theme.spacing.unit * 2,
		overflowX: 'auto',
		[theme.breakpoints.up('lg')]: {
			marginLeft: drawerWidth,
			width: `calc(100% - ${drawerWidth}px)`,
		},
	},
	contentDisabledMenu: {
		flexGrow: 1,
		padding: theme.spacing.unit * 2,
		overflowX: 'auto',
	},
	chartContainer: {
		marginLeft: -22,
	},
	tableContainer: {
		height: 320,
	},
	h5: {
		marginBottom: theme.spacing.unit * 2,
	},
});

class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		this.handleLogout = this.handleLogout.bind(this);
		this.handleExpansionPanelChanged = this.handleExpansionPanelChanged.bind(this);
		this.handleRefreshLog = this.handleRefreshLog.bind(this);
		this.handleClearLog = this.handleClearLog.bind(this);
		this.handleDocumentation = this.handleDocumentation.bind(this);
		
		this.state = {
			logs: "",
		};
		
		// Deep Linking, load context (model && personalization) if not loaded before
		if (this.props.context.model == null) {
			
			this.props.context.showActivityIndicator();
			refreshPersonalization(this.props.theme, this.props.context.baseUrl).then(result => {
				//this.forceUpdate();
				
				if (this.props.theme.supportedLanguages.filter(language => i18n.language == language).length == 0) {
					i18n.changeLanguage(this.props.theme.supportedLanguages[0]);
				}
				else {
					i18n.changeLanguage(i18n.language);
				}
				
				let hash = window.location.hash;
				if (hash != null && hash != "") {
					hash.split(/[#&,]/).forEach(item => { 
						if (item.split("=")[0] == "access_token") { 
							
							let accessToken = item.split("=")[1];

							const query = '{ getCurrentUser { currentUser }}';
							
							let variables = {
								"authorization": accessToken,
					    	};
							let request = JSON.stringify({query: query, variables: variables});
							fetch(this.props.context.baseUrl + "/graphql", {
								method: "POST",
								body: request
							})
							.then(response => response.json())
							.then(json => {
								if (json != null
										&& json.errors != null
										&& json.errors[0] != null
										&& json.errors[0].message == "SessionTimeout") {
									document.location = '/login';
								}
								
								if (json.errors != null) {
									console.log(json.errors);
								}
								else {
									if (json.data.getCurrentUser.currentUser != null && json.data.getCurrentUser.currentUser != "anonymous") {
										refreshModels(item.split("=")[1], json.data.getCurrentUser.currentUser, this.props.context).then(result => {
											//this.forceUpdate();
										});
									}
									else {
										history.replace("/login");
									}
								}
							});
						}
					});
				}
				else {
					refreshModels(null, "anonymous", this.props.context).then(result => {
						this.setState({
								display: "",
								language: i18n.language,
							}, () => {
						});
					});
				}
			});
			
		}
	}
	
	handleExpansionPanelChanged(event, expanded) {
		if (expanded) {
			setTimeout(function() {
				window.scrollTo(0, document.body.scrollHeight)
			}, 200);
		}
	}
	
	handleRefreshLog(event) {
		this.props.context.showActivityIndicator();
		
		let logs = this.state.logs;
		
		let query = "{ getLog }";
		if (logs != "") {
			query = "{ getLog(since: " + (Math.floor(new Date(logs.substr(logs.lastIndexOf("\n", logs.length - 2) + 1, 30)).getTime() / 1000) + 1) + ") }";
		}
		
		const variables = {
			authorization: this.props.context.accessToken,
		};
		
		let request = JSON.stringify({query: query, variables: variables});
		fetch(this.props.context.baseUrl + "/graphql", {
			method: "POST",
			body: request
		})
		.then(response => response.json())
		.then(json => {
			if (json != null
					&& json.errors != null
					&& json.errors[0] != null
					&& json.errors[0].message == "SessionTimeout") {
				document.location = '/login';
			}
			
			this.props.context.hideActivityIndicator();
			
			if (json.errors != null) {
				this.setState({
					message: json.errors[0].message,
					messageError: true,
					messageOpened: true,
				});
			}
			else {
				logs += json.data.getLog;
				this.setState((state, props) => ({
					logs: logs,
				}), () => window.scrollTo(0, document.body.scrollHeight));
			}
		});
	}
	
	handleClearLog(event) {
		this.setState({
			logs: "",
		});
	}
	
	handleLogout(event) {
		if (this.props.theme.enableIAM) {
			document.location = '/auth/realms/airflows/protocol/openid-connect/logout?redirect_uri=' + this.props.context.baseUrl + '/login';
		}
		else {
			history.push("/login");
		}
		this.props.context.state = {};
		this.props.context.setState({
			username: null,
			accessToken: null,
			model: null
		});
	}
	
	handleDocumentation(event) {
		window.open(this.props.context.baseUrl + "/documentation", this.props.t('help'));
	}
	
	render() {
		const { classes, t, theme } = this.props;
		
		const model = this.props.context.model;
		
		let entities = null;
		let menuGroups = {};
		
		if (model != null && window.location.pathname == '/admin' && model.defaultEntity != null && model.defaultEntity != '') {
			history.push("/admin/" + model.defaultEntity);
		}
		
		let drawerContents = <></>;
		
		if (model != null) {
			entities = Object.values(model.entities)
					.filter(entity => model.edition == 'Enterprise' 
							|| entity.schema != 'Models'
							|| entity.name == 'Entity'
							|| entity.name == 'Role'
							|| entity.name == 'User'
							|| entity.name == 'Dashboard'
							|| entity.name == 'Personalization'
					)
					.filter(entity => model.super && localModel.entities[entity.schema + "." + entity.name] && localModel.entities[entity.schema + "." + entity.name].menu
							|| model.entities[entity.schema + "." + entity.name].privileges != null && model.entities[entity.schema + "." + entity.name].privileges["MENU"])
					.concat(Object.keys(localModel.entities).filter(key => localModel.entities[key].external && localModel.entities[key].menu).map(key => { let item = localModel.entities[key]; item.schema = key.split(".")[0]; item.name = key.split(".")[1]; return item}))
					.sort((a, b) => localModel.entities[a.schema + "." + a.name].order - localModel.entities[b.schema + "." + b.name].order);
		
			if (entities != null) {
				entities.forEach(entity => {
					menuGroups[entity.schema] = entity.schema;
				});
			}

			drawerContents = <>
				{
					theme.showLogoInMenu
							&& (this.props.context.disableHeader || !theme.showLogoInHeader)
							&&
					<div className={classes.toolbarIcon} style={{alignItems: "center"}}>
						<div style={{flex: "1 1 100%", textAlign: "center"}}>
							<img src={theme.customerLogoImageSrc} style={{maxWidth: "160px", maxHeight: "50px"}} alt=""/>
						</div>
					</div>
				}
				
				<List styles={{paddingTop: "0"}}>
					{
					/*
					<ListItem key="VirtualAnalyst" button onClick={event => history.push("/admin")}>
						<Tooltip title={t("virtualAnalyst")} disableFocusListener>
							<ListItemIcon>
								<Icon>assistant</Icon>
							</ListItemIcon>
						</Tooltip>
						<ListItemText primary={t("virtualAnalyst")}/>
					</ListItem>
					*/
					}
					{
						entities != null
								&& Object.values(menuGroups)
										.sort((a, b) => (a < b ? (-1) : (a > b ? 1 : 0)))
										.map(menuGroup => menuGroup == "Models" && (
							<div key={menuGroup}>
								<Typography
										noWrap
										classes={{
											root: classNames(classes.highlightedMenuGroup, !this.props.context.open && classes.menuGroupClose),
										}}>
									{menuGroup}
								</Typography>
								<Typography
										noWrap
										classes={{
											root: classNames(classes.highlightedMenuGroupCaption, !this.props.context.open && classes.menuGroupClose),
										}}>
									{t("modelingTool")}
								</Typography>
								{
									entities
											.filter(entity => entity.schema === menuGroup)
											.map(entity => (
										<ListItem style={{ borderLeftWidth: (this.state.lastSelectedMenu == entity.schema + "." + entity.name ? 3 : 0), borderLeftStyle: "solid", borderColor: theme.palette.primary.main, backgroundColor: (this.state.lastSelectedMenu == entity.schema + "." + entity.name ? "#F0F0F0" : "#FFFFFF") }} key={entity.schema + "." + entity.name} button onClick={event => { this.setState({ "lastSelectedMenu": entity.schema + "." + entity.name }); history.push("/admin/" + entity.schema + "." + entity.name + (entity.external ? "/external" : "")); }}>
											<Tooltip title={t("e." + entity.schema + "." + entity.name + ".pluralName")} disableFocusListener>
												<ListItemIcon style={{ paddingLeft: "10px" }} data-qa={entity.schema + "." + entity.name + "-menu"}>
													<Icon>{(localModel.entities[entity.schema + "." + entity.name].icon != null ? localModel.entities[entity.schema + "." + entity.name].icon : "warning")}</Icon>
												</ListItemIcon>
											</Tooltip>
											<Typography style={{ fontWeight: (this.state.lastSelectedMenu == entity.schema + "." + entity.name ? 600 : 400), paddingLeft: "0px", overflow: "hidden", textOverflow: "ellipsis"}}>{t("e." + entity.schema + "." + entity.name + ".pluralName")}</Typography>
										</ListItem>
									))
								}
							</div>
						))
					}
					{
						model.dashboards.length > 0
								&&
							<div key="dashboardsGroup">
								<Typography
										noWrap
										classes={{
											root: classNames(classes.menuGroup, !this.props.context.open && classes.menuGroupClose),
										}}>
									{t("dashboardsGroup")}
								</Typography>
								{
									model.dashboards.map(dashboard => (
										<ListItem style={{ borderLeftWidth: (this.state.lastSelectedMenu == "_dashboard-" + dashboard.id ? 3 : 0), borderLeftStyle: "solid", borderColor: theme.palette.primary.main, backgroundColor: (this.state.lastSelectedMenu == "_dashboard-" + dashboard.id ? "#F0F0F0" : "#FFFFFF") }} key={dashboard.name} button onClick={event => { this.setState({ "lastSelectedMenu": "_dashboard-" + dashboard.id }); history.push("/dashboard/" + dashboard.id + "/" + dashboard.name)}}>
											<Tooltip title={t("dashboards." + dashboard.name)} disableFocusListener>
												<ListItemIcon style={{ paddingLeft: "10px" }}>
													<DashboardIcon/>
												</ListItemIcon>
											</Tooltip>
											<Typography style={{ fontWeight: (this.state.lastSelectedMenu == "_dashboard-" + dashboard.id ? 600 : 400), paddingLeft: "0px", overflow: "hidden", textOverflow: "ellipsis"}}>{t("dashboards." + dashboard.name)}</Typography>
										</ListItem>
									))
								}
							</div>
					}
					{
						entities != null
								&& Object.values(menuGroups)
										.sort((a, b) => (a < b ? (-1) : (a > b ? 1 : 0)))
										.map(menuGroup => menuGroup != "Models" && (
							<div key={menuGroup}>
								<Typography
										noWrap
										classes={{
											root: classNames(classes.menuGroup, !this.props.context.open && classes.menuGroupClose),
										}}>
									{menuGroup}
								</Typography>
								{
									entities
											.filter(entity => entity.schema === menuGroup)
											.map(entity => (
										<ListItem style={{ borderLeftWidth: (this.state.lastSelectedMenu == entity.schema + "." + entity.name ? 3 : 0), borderLeftStyle: "solid", borderColor: theme.palette.primary.main, backgroundColor: (this.state.lastSelectedMenu == entity.schema + "." + entity.name ? "#F0F0F0" : "#FFFFFF") }} key={entity.schema + "." + entity.name} button onClick={event => { this.setState({ "lastSelectedMenu": entity.schema + "." + entity.name }); history.push("/admin/" + entity.schema + "." + entity.name + (entity.external ? "/external" : "")); }}>
											<Tooltip title={t("e." + entity.schema + "." + entity.name + ".pluralName")} disableFocusListener>
												<ListItemIcon style={{ paddingLeft: "10px" }} data-qa={entity.schema + "." + entity.name + "-menu"}>
													<Icon>{(localModel.entities[entity.schema + "." + entity.name].icon != null ? localModel.entities[entity.schema + "." + entity.name].icon : "warning")}</Icon>
												</ListItemIcon>
											</Tooltip>
											<Typography style={{ fontWeight: (this.state.lastSelectedMenu == entity.schema + "." + entity.name ? 600 : 400), paddingLeft: "0px", overflow: "hidden", textOverflow: "ellipsis"}}>{t("e." + entity.schema + "." + entity.name + ".pluralName")}</Typography>
										</ListItem>
									))
								}
							</div>
						))
					}
					{
							model.super
								&&
						<div key="adminGroup">
							<Typography
									noWrap
									style={{ marginBottom: "10px" }}
									classes={{
										root: classNames(classes.highlightedMenuGroup, !this.props.context.open && classes.menuGroupClose),
									}}>
								{t("adminGroup")}
							</Typography>
							<ListItem style={{ borderLeftWidth: (this.state.lastSelectedMenu == "_admin" ? 3 : 0), borderLeftStyle: "solid", borderColor: theme.palette.primary.main, backgroundColor: (this.state.lastSelectedMenu == "_admin" ? "#F0F0F0" : "#FFFFFF") }} key="admin" button onClick={event => { this.setState({ "lastSelectedMenu": "_admin" }); history.push("/tools/admin") }}>
								<Tooltip title={t("admin")} disableFocusListener>
									<ListItemIcon style={{ paddingLeft: "10px" }} data-qa="admin-menu">
										<AdminIcon/>
									</ListItemIcon>
								</Tooltip>
								<Typography style={{ fontWeight: (this.state.lastSelectedMenu == "_admin" ? 600 : 400), paddingLeft: "0px", overflow: "hidden", textOverflow: "ellipsis"}}>{t("admin")}</Typography>
							</ListItem>
							<ListItem key="documentation" button onClick={this.handleDocumentation}>
								<Tooltip title={t("documentation")} disableFocusListener>
									<ListItemIcon style={{ paddingLeft: "10px" }} data-qa="documentation-menu">
										<HelpIcon/>
									</ListItemIcon>
								</Tooltip>
								<Typography style={{ paddingLeft: "0px", overflow: "hidden", textOverflow: "ellipsis"}}>{t("documentation")}</Typography>
							</ListItem>
						</div>
					}
					<div style={{flex: "1 1 100%", textAlign: "center", paddingTop: "30px"}}>
						<UserIcon fontSize="large"/>
						<Typography
								noWrap>
							{this.props.context.username}
						</Typography>
					</div>
					<div style={{ textAlign: "center", padding: "20px"}} key="closeSessionGroup">
						<Button onClick={this.handleLogout} variant="contained">
							<Icon>{"close"}</Icon>
							<div style={{ paddingLeft: 10 }}>{t("closeSession")}</div>
						</Button>
					</div>
				</List>
			</>;
		}
		
		return (
			model != null
			&&
			<div className={classes.container}>
				<div className={classes.root}>
					<CssBaseline />
					
					{
						!this.props.context.disableHeader
								&&
						<AppBar position="fixed" className={(this.props.context.disableMenu ? classNames(classes.appBarDisabledMenu) : classNames(classes.appBar))}>
							<Toolbar disableGutters style={{backgroundColor: theme.palette.primary.main}} className={classes.toolbar}>
								{
									!this.props.context.disableMenu
											&&
									<Hidden lgUp>
										<IconButton
												color="inherit"
												aria-label="Open drawer"
												data-qa="open-drawer-button"
												onClick={this.props.context.handleDrawerOpen}
												className={classNames(
													classes.menuButton,
												)}>
											<MenuIcon />
										</IconButton>
									</Hidden>
								}
								{
									theme.showLogoInHeader
											&&
									<img src={theme.customerLogoImageSrc} style={{marginLeft: 20, maxWidth: "160px", maxHeight: "50px"}} alt=""/>
								}
								{
									theme.titles != null && theme.titles.filter(title => title.locale == i18n.language).length > 0
											&& 
										<Typography
												data-qa="title-label"
												variant="h6"
												color="inherit"
												noWrap
												className={classes.title}>
											{theme.titles.filter(title => title.locale == i18n.language)[0].title}
										</Typography>
											||
										<Typography
												data-qa="title-label"
												variant="h6"
												color="inherit"
												noWrap
												className={classes.title}>
											{t('title')}
										</Typography>
								}
								<IconButton
										color="inherit"
										onClick={() => history.goBack()}>
									<ArrowBackIcon />
								</IconButton>
								<IconButton
										color="inherit"
										onClick={() => history.goForward()}>
									<ArrowForwardIcon />
								</IconButton>
							</Toolbar>
						</AppBar>
					}
	
					{
						!this.props.context.disableMenu 
								&&
						<>
							<Hidden lgUp>
								<Drawer
										onClose={this.props.context.handleDrawerClose}
										variant="temporary"
										classes={{
											paper: classNames(classes.drawerPaper),
										}}
										open={this.props.context.open}>
									{drawerContents}
								</Drawer>
							</Hidden>
							<Hidden mdDown implementation="css">
								<Drawer
										classes={{
											paper: classes.drawerPaper,
										}}
										variant="permanent"
										open
								>
									{drawerContents}
								</Drawer>
							</Hidden>
						</>
					}
					<main className={(this.props.context.disableMenu ? classes.contentDisabledMenu : classes.content)}>
						{
							!this.props.context.disableHeader
									&&
							<div className={classes.appBarSpacer} />
						}
						{
							(this.props.match.path === '/admin' 
									&& 
								<></>//<VirtualAnalyst/>
							)
							|| (this.props.match.path === '/tools/:toolName' 
									&& (
								(this.props.match.params.toolName === 'admin'
										&&
									<AdminTools/>
								))
							)
							|| (this.props.match.path === '/dashboard/:id/:name' 
										&& 
									<DashboardTool id={this.props.match.params.id} name={this.props.match.params.name}/>
							)
							|| (this.props.match.path === '/admin/:entityName' 
									&& 
								<EntityList disabled={false} main mode="edit" entity={this.props.match.params.entityName} theme={theme}/>
							)
							|| (this.props.match.path === '/admin/:entityName/external' 
									&& 
								<Paper style={{height: "100%"}}>
									<iframe className={classes.iframe} src={localModel.entities[this.props.match.params.entityName].url}></iframe>
								</Paper>
							)
							|| (this.props.match.path === '/admin/:entityName/new' 
									&& 
								<EntityView mode="edit" entity={this.props.match.params.entityName} theme={theme}/>
							)
							|| (this.props.match.path === '/admin/:entityName/new/:preselectedAttribute/:preselectedValue' 
									&& 
								<EntityView mode="edit" entity={this.props.match.params.entityName} preselectedAttribute={this.props.match.params.preselectedAttribute} preselectedValue={this.props.match.params.preselectedValue} theme={theme}/>
							)
							|| (this.props.match.path === '/admin/:entityName/:entityId/view' 
									&& 
								<EntityView mode="view" entity={this.props.match.params.entityName} entityId={this.props.match.params.entityId} theme={theme}/>
							)
							|| (this.props.match.path === '/admin/:entityName/:entityId/edit' 
									&& 
								<EntityView mode="edit" entity={this.props.match.params.entityName} entityId={this.props.match.params.entityId} theme={theme}/>
							)
							|| (this.props.match.path === '/admin/:entityName/:entityId/:attributeName/edit' 
									&& 
								<Paper style={{height: "100%"}}>
									<iframe allow="fullscreen" className={classes.iframe} src={this.props.context.loolEndpoint + "/loleaflet/dist/loleaflet.html?file_path=" + this.props.context.baseUrl + "/wopi/files/" + this.props.match.params.entityName.replace(".", "/") + "/" + this.props.match.params.entityId + "/" + this.props.match.params.attributeName + "?access_token=" + this.props.context.accessToken} title="Document"></iframe>
								</Paper>
							)
						}
					</main>
				</div>
				{
					model.super 
							&&
					<ExpansionPanel	square className={(this.props.context.disableMenu ? classes.logViewerDisabledMenu : classes.logViewer)} onChange={this.handleExpansionPanelChanged}>
						<ExpansionPanelSummary> {/* expandIcon={<ExpandMoreIcon />}> */ }
							<Typography className={classes.heading}>{this.props.t('logViewer')}</Typography>
						</ExpansionPanelSummary>
						<ExpansionPanelDetails>
							<div className={classes.logContainer}>
								<pre className={classes.pre}>
									{(this.state != null ? this.state.logs : "")}
								</pre>
								<div className={classes.logToolbar}>
									{/*
									<Input inputRef={this.searchInput} placeholder={t('tail')} autofocus/>
									*/}
									<Tooltip title={t('refreshLog')} disableFocusListener>
										<IconButton 
												disabled={this.props.disabled}
												aria-label={t('refreshLog')} 
												onClick={this.handleRefreshLog}>
											<RefreshIcon/>
										</IconButton>
									</Tooltip>
									<Tooltip title={t('clearLog')} disableFocusListener>
										<IconButton 
												disabled={this.props.disabled}
												aria-label={t('clearLog')} 
												onClick={this.handleClearLog}>
											<ClearIcon/>
										</IconButton>
									</Tooltip>
								</div>
							</div>
						</ExpansionPanelDetails>
					</ExpansionPanel>
				}

				{
				/*
				<Dialog style={{zIndex: 10000}}
						open={model && !model.agreementAccepted && model.username != "anonymous"}
						onClose={event => console.log("hh")}>
					<DialogTitle>{t('deleteConfirmation')}</DialogTitle>
					<DialogContent>
						<DialogContentText>{t('deleteQuestion') + " " + " " + t('selected') + "?"}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={event => this.setState({deleteConfirmationDialogOpened: false})}>{t('cancel')}</Button>
						&nbsp;
						<Button onClick={this.handleDeleteDebounced}>{t('delete')}</Button>
					</DialogActions>
				</Dialog>
				*/
				}
				
				<Dialog 
						scroll="paper" 
						open={model && !model.agreementAccepted && model.username != "anonymous"}
						onClose={this.onPreviewCloseDialog} 
						classes={{ paper: classes.previewDialogPaper }}>
					<MuiDialogTitle>
						<IconButton aria-label="close" className={classes.closeButton} onClick={this.onPreviewCloseDialog}>
							<CloseIcon />
				        </IconButton>
					</MuiDialogTitle>
					<DialogContent className={classes.dialogContent}>
						<iframe className={classes.iframe} src={this.props.context.baseUrl + "/agreement-es.pdf"} title="Subscription agreement"></iframe>
					</DialogContent>
				</Dialog>
			</div>
		);
	}
}

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(withContext(withI18n()(Dashboard))));
