import { resources } from './i18n';
import { localModel } from './localModel';
import history from './history';
import tinycolor from 'tinycolor2';

export async function refreshModels(accessToken, username, context) {
	const query2 = '{ model: getModel }';
	
	let variables2 = {
		"authorization": accessToken
	};
	let request2 = JSON.stringify({query: query2, variables: variables2});
	fetch(context.baseUrl + "/graphql", {
		method: "POST",
		body: request2
	})
	.then(response => response.json())
	.then(json2 => {
		if (json2 != null
				&& json2.errors != null
				&& json2.errors[0] != null
				&& json2.errors[0].message == "SessionTimeout") {
			document.location = '/login';
		}
		
		if (json2.errors != null) {
			console.log(json2.errors);
		}
		else {
			// localModel & i18n
			const query3 = '{ '
				+ '   externalEntities: Models_ExternalEntityList { '
				+ '	    schema '
				+ '	    name '
				+ '     url '
				+ '	    order '
				+ '	    menu '
				+ '	    icon '
				+ '     labels: ExternalEntityLabelListViaContainer { '
				+ '       locale '
				+ '       label '
				+ '       pluralLabel '
				+ '     } '
				+ '	  } '
				+ '   entities: Models_EntityList { '
				+ '	    schema '
				+ '	    name '
				+ '     language '
				+ '	    order '
				+ '	    menu '
				+ '	    icon '
				+ '     exportToCSVEnabled: isExportToCSVEnabled '
				+ '     labels: EntityLabelListViaContainer { '
				+ '       locale '
				+ '       label '
				+ '       pluralLabel '
				+ '     } '
				+ '	    attributes: EntityAttributeListViaContainer { '
				+ '	      name '
				+ '	      order '
				+ '	      type '
				+ '	      customType '
				+ '	      enumType '
				+ '	      required: isRequired '
				+ '	      label: isLabel '
				+ '	      list: isList '
				+ '	      visible: isVisible '
				+ '	      basicFilter: isBasicFilter '
				+ '	      textFilter: isTextFilter '
				+ '	      textSearch: isTextSearch '
				+ '	      lastInRow: isLastInRow '
				+ '	      hidden: isHidden '
				+ '	      eventStart: isEventStart '
				+ '	      eventEnd: isEventEnd '
				+ '	      pointInMap: isPointInMap '
				+ '	      imageInGallery: isImageInGallery '
				+ '	      indexDocuments: indexDocuments '
				+ '       browseZip: browseZip '
				+ '	      computed: isComputed '
				+ '	      array: isArray '
				+ '	      tab '
				+ '	      group '
				+ '	      variantSelector: isVariantSelector '
				+ '	      variants '
				+ '	      xs '
				+ '	      sm '
				+ '       length '
				+ '	      multiline: isMultiline '
				+ '	      action: isAction '
				+ '       precision '
				+ '	      scale '
				+ '	      min '
				+ '	      max '
				+ '	      step '
				+ '	      prefix '
				+ '	      suffix '
				+ '       pattern '
				+ '       password: isPassword '
				+ '       icon: isIcon '
				+ '       color: isColor '
				+ '	      acceptedFileTypes '
				+ '	      maxFiles '
				+ '	      barcodeType '
				+ '	      mrzField '
				+ '	      defaultValue '
				+ '	      labels: EntityAttributeLabelListViaContainer { '
				+ '	        locale '
				+ '	        label '
				+ '	      } '
				+ '	    } '
				+ '	    tabs: EntityTabListViaContainer { '
				+ '	      id '
				+ '	      name '
				+ '	      order '
				+ '	      labels: EntityTabLabelListViaContainer { '
				+ '	        locale '
				+ '	        label '
				+ '	      } '
				+ '	    } '
				+ '	    groups: EntityGroupListViaContainer { '
				+ '	      id '
				+ '	      name '
				+ '	      order '
				+ '	      labels: EntityGroupLabelListViaContainer { '
				+ '	        locale '
				+ '	        label '
				+ '	      } '
				+ '	    } '
				+ '     directReferences: EntityReferenceListViaContainer { '
				+ '       name '
				+ '	      additionalFilter '
				+ '       referencedKey: EntityKeyViaReferencedKey { '
				+ '         entity: EntityViaContainer { '
				+ '           name '
				+ '         } '
				+ '       } '
				+ '       attributes: EntityReferenceAttributeListViaContainer { '
				+ '         attribute: EntityAttributeViaAttribute { '
				+ '           name '
				+ '         } '
				+ '       } '
				+ '       visible: isVisible '
				+ '       list: isList '
				+ '       label: isLabel '
				+ '	      basicFilter: isBasicFilter '
				+ '	      linkDisabled: isLinkDisabled '
				+ '       order '
				+ '       tab '
				+ '       group '
				+ '       xs '
				+ '       sm '
				+ '     } '
				+ '	    inverseReferences: EntityKeyListViaContainer { '
				+ '       references: EntityReferenceListViaReferencedKey { '
				+ '         name '
				+ '         entity: EntityViaContainer { '
				+ '           name '
				+ '         } '
				+ '         attributes: EntityReferenceAttributeListViaContainer { '
				+ '           attribute: EntityAttributeViaAttribute { '
				+ '             name '
				+ '           } '
				+ '         } '
				+ '         visible: listIsVisible '
				+ '         filteredWhenEmpty: listIsFilteredWhenEmpty '
				+ '         cascadeDelete: isCascadeDelete '
				+ '         cascadeSetNull: isCascadeSetNull '
				+ '         order: listOrder '
				+ '         tab: listTab '
				+ '         group: listGroup '
				+ '         xs: listXs '
				+ '       	sm: listSm '
				+ '       } '
				+ '     } '
				+ '	    questions: QuestionListViaContainer { '
				+ '       id '
				+ '	      name '
				+ '	      questionId '
				+ '	      list: isList '
				+ '	      visible: isVisible '
				+ '	      useIds '
				+ '	      order '
				+ '	      labels: QuestionLabelListViaContainer { '
				+ '	        locale '
				+ '	        label '
				+ '	      } '
				+ '	    } '
				+ '     intelligenceModels: IntelligenceModelListViaEntity { '
				+ '       name '
				+ '       label: EntityAttributeViaLabel { '
				+ '         name '
				+ '         type '
				+ '       } '
				+ '       features: IntelligenceFeatureListViaContainer { '
				+ '         attribute: EntityAttributeViaAttribute { '
				+ '           name '
				+ '           type '
				+ '         } '
				+ '       } '
				+ '     } '
				+ '	  } '
				+ '   dashboards: Models_DashboardList { '
				+ '     id '
				+ '     name '
				+ '     labels: DashboardLabelListViaContainer { '
				+ '       locale '
				+ '       label '
				+ '     } '
				+ '   } '
				+ '   enumTypes: Models_EnumTypeList { '
				+ '	    schema '
				+ '	    name '
				+ '     values '
				+ '     labels: EnumTypeLabelListViaContainer { '
				+ '       locale '
				+ '       labels '
				+ '     } '
				+ '   } '
				+ (username != null && username != "anonymous" ? 
					  '   users: Models_UserList( '
					+ '     where: { '
					+ '       username: {EQ: "' + username + '"} '
					+ '     } '
					+ '   ) { '
					+ '     phoneIpAddress '
					+ '     phonePassword '
					+ '		phoneRedirectAttribute: EntityAttributeViaPhoneRedirectAttribute { '
					+ ' 		name '
					+ '			entity: EntityViaContainer { '
					+ '				schema '
					+ '				name '
					+ '			} '
					+ '		} '
					+ '   } '
					: '')
				+ '   errorMessages: Models_ErrorMessageList { '
				+ '	    name '
				+ '	    regularExpression '
				+ '     labels: ErrorMessageLabelListViaContainer { '
				+ '       locale '
				+ '       message '
				+ '     } '
				+ '   } '
				+ '}';
			
			const variables3 = {
	    		authorization: accessToken
	    	};
			let request3 = JSON.stringify({query: query3, variables: variables3});
			
			if (context.socket != null) {
				context.socket.close();
			}
			
			fetch(context.baseUrl + "/graphql", {
				method: "POST",
				body: request3
			})
			.then(response => response.json())
			.then(json3 => {
				if (json3.data != null
						&& json3.data.users != null
						&& json3.data.users.length > 0
						&& json3.data.users[0].phoneIpAddress != null
						&& json3.data.users[0].phoneRedirectAttribute != null) {
					
					context.socket = new WebSocket("ws://" + json3.data.users[0].phoneIpAddress + ":8887");
					context.socket.onmessage = event => {
						console.log("Incoming call from: " + event.data);
						
						let phoneRedirectAttributeName = json3.data.users[0].phoneRedirectAttribute.name;
						let schema = json3.data.users[0].phoneRedirectAttribute.entity.schema;
						let entityName = json3.data.users[0].phoneRedirectAttribute.entity.name;
						
						// Get id
						const query4 = '{ '
							+ '   entity: ' + schema + '_' + entityName + 'List( '
							+ '     where: { '
							+ '       ' + phoneRedirectAttributeName + ': {EQ: "' + event.data + '"} '
							+ '     } '
							+ '   ) { '
							+ '     id '
							+ '   } '
							+ '}';
						
						const variables4 = {
				    		authorization: accessToken
				    	};
						let request4 = JSON.stringify({query: query4, variables: variables4});
						
						fetch(context.baseUrl + "/graphql", {
							method: "POST",
							body: request4
						})
						.then(response => response.json())
						.then(json4 => {
							if (json4.data != null
									&& json4.data.entity != null
									&& json4.data.entity.length > 0
									&& json4.data.entity[0].id != null) {
								history.push("/admin/" + schema + "." + entityName + "/" + json4.data.entity[0].id + "/view");
							}
						});
					};
				}
				
				if (json3.data != null && json3.data.dashboards != null) {
					json3.data.dashboards.forEach(dashboard => {
						if (dashboard.labels != null) {
							dashboard.labels.forEach(label => {
								if (resources[label.locale] == null) {
									resources[label.locale] = {};
								}
								if (resources[label.locale].translation == null) {
									resources[label.locale].translation = {};
								}
								if (resources[label.locale].translation.dashboards == null) {
									resources[label.locale].translation.dashboards = {};
								}
								resources[label.locale].translation.dashboards[dashboard.name] = {
									v: {}
								};
								resources[label.locale].translation.dashboards[dashboard.name] = label.label;
							});
						}
					});
				}
				
				if (json3.data != null && json3.data.enumTypes != null) {
					json3.data.enumTypes.forEach(enumType => {
						if (enumType.labels != null) {
							enumType.labels.forEach(label => {
								if (resources[label.locale] == null) {
									resources[label.locale] = {};
								}
								if (resources[label.locale].translation == null) {
									resources[label.locale].translation = {};
								}
								if (resources[label.locale].translation.enums == null) {
									resources[label.locale].translation.enums = {};
								}
								if (resources[label.locale].translation.enums[enumType.schema] == null) {
									resources[label.locale].translation.enums[enumType.schema] = {};
								}
								resources[label.locale].translation.enums[enumType.schema][enumType.name] = {
									v: {}
								};
								label.labels.forEach((valueLabel, i) => {
									resources[label.locale].translation.enums[enumType.schema][enumType.name].v[enumType.values[i]] = valueLabel;
								});
							});
						}
					});
				}
				
				if (json3.data != null && json3.data.externalEntities != null) {
					json3.data.externalEntities.forEach(entity => {
						
						localModel.entities[entity.schema + "." + entity.name] = {
							icon: entity.icon,
							menu: entity.menu,
							order: entity.order,
							exportToCSVEnabled: entity.exportToCSVEnabled,
							external: true,
							url: entity.url,
						};
						
						if (entity.labels != null) {
							entity.labels.forEach(label => {
								if (resources[label.locale].translation.e[entity.schema] == null) {
									resources[label.locale].translation.e[entity.schema] = {};
								}
								resources[label.locale].translation.e[entity.schema][entity.name] = {
									name: label.label,
									pluralName: label.pluralLabel,
									a: {}
								};
							});
						}
					});
				}
				
				if (json3.data != null && json3.data.entities != null) {
					json3.data.entities.forEach(entity => {
						let attributes = {};
						if (entity.attributes != null) {
							entity.attributes.forEach(attribute => {
								attributes[attribute.name] = attribute;
							});
						}
						
						let tabs = null;
						if (entity.tabs != null) {
							tabs = {};
							entity.tabs.forEach(tab => {
								tabs[tab.id] = tab;
							});
						}
						
						let groups = null;
						if (entity.groups != null) {
							groups = {};
							entity.groups.forEach(group => {
								groups[group.id] = group;
							});
						}
						
						let questions = null;
						if (entity.questions != null) {
							questions = {};
							entity.questions.forEach(question => {
								questions[question.name] = question;
							});
						}
						
						let intelligenceModels = null;
						if (entity.intelligenceModels != null) {
							intelligenceModels = {};
							entity.intelligenceModels.forEach(intelligenceModel => {
								intelligenceModels[intelligenceModel.name] = intelligenceModel;
							});
						}
						
						localModel.entities[entity.schema + "." + entity.name] = {
							icon: entity.icon,
							menu: entity.menu,
							exportToCSVEnabled: entity.exportToCSVEnabled,
							language: entity.language,
							order: entity.order,
							attributes: attributes,
							tabs: tabs,
							groups: groups,
							questions: questions,
							intelligenceModels: intelligenceModels,
						};
						
						if (entity.labels != null) {
							entity.labels.forEach(label => {
								if (resources[label.locale] == null) {
									resources[label.locale] = {};
								}
								if (resources[label.locale].translation == null) {
									resources[label.locale].translation = {};
								}
								if (resources[label.locale].translation.e == null) {
									resources[label.locale].translation.e = {};
								}
								if (resources[label.locale].translation.e[entity.schema] == null) {
									resources[label.locale].translation.e[entity.schema] = {};
								}
								resources[label.locale].translation.e[entity.schema][entity.name] = {
									name: label.label,
									pluralName: label.pluralLabel,
									a: {}
								};
							});
							
							if (entity.attributes != null) {
								entity.attributes.forEach(attribute => {
									if (attribute.labels != null) {
										attribute.labels.forEach(label => {
											if (resources[label.locale] == null) {
												resources[label.locale] = {};
											}
											if (resources[label.locale].translation == null) {
												resources[label.locale].translation = {};
											}
											if (resources[label.locale].translation.e == null) {
												resources[label.locale].translation.e = {};
											}
											if (resources[label.locale].translation.e[entity.schema] == null) {
												resources[label.locale].translation.e[entity.schema] = {};
											}
											if (resources[label.locale].translation.e[entity.schema][entity.name] == null) {
												resources[label.locale].translation.e[entity.schema][entity.name] = {a:{}};
											}
											resources[label.locale].translation.e[entity.schema][entity.name].a[attribute.name] = label.label;
										});
									}
								});
							}
						}

						if (entity.tabs != null) {
							entity.tabs.forEach(tab => {
								if (tab.labels != null) {
									tab.labels.forEach(label => {
										if (resources[label.locale] == null) {
											resources[label.locale] = {};
										}
										if (resources[label.locale].translation == null) {
											resources[label.locale].translation = {};
										}
										if (resources[label.locale].translation.e == null) {
											resources[label.locale].translation.e = {};
										}
										if (resources[label.locale].translation.e[entity.schema] == null) {
											resources[label.locale].translation.e[entity.schema] = {};
										}
										if (resources[label.locale].translation.e[entity.schema][entity.name] == null) {
											resources[label.locale].translation.e[entity.schema][entity.name] = {};
										}
										if (resources[label.locale].translation.e[entity.schema][entity.name].tabs == null) {
											resources[label.locale].translation.e[entity.schema][entity.name].tabs = {};
										}
										resources[label.locale].translation.e[entity.schema][entity.name].tabs["" + tab.id] = label.label;
									});
								}
							});
						}
						
						if (entity.groups != null) {
							entity.groups.forEach(group => {
								if (group.labels != null) {
									group.labels.forEach(label => {
										if (resources[label.locale] == null) {
											resources[label.locale] = {};
										}
										if (resources[label.locale].translation == null) {
											resources[label.locale].translation = {};
										}
										if (resources[label.locale].translation.e == null) {
											resources[label.locale].translation.e = {};
										}
										if (resources[label.locale].translation.e[entity.schema] == null) {
											resources[label.locale].translation.e[entity.schema] = {};
										}
										if (resources[label.locale].translation.e[entity.schema][entity.name] == null) {
											resources[label.locale].translation.e[entity.schema][entity.name] = {};
										}
										if (resources[label.locale].translation.e[entity.schema][entity.name].groups == null) {
											resources[label.locale].translation.e[entity.schema][entity.name].groups = {};
										}
										resources[label.locale].translation.e[entity.schema][entity.name].groups["" + group.id] = label.label;
									});
								}
							});
						}
						
						if (entity.questions != null) {
							entity.questions.forEach(question => {
								if (question.labels != null) {
									question.labels.forEach(label => {
										if (resources[label.locale] == null) {
											resources[label.locale] = {};
										}
										if (resources[label.locale].translation == null) {
											resources[label.locale].translation = {};
										}
										if (resources[label.locale].translation.e == null) {
											resources[label.locale].translation.e = {};
										}
										if (resources[label.locale].translation.e[entity.schema] == null) {
											resources[label.locale].translation.e[entity.schema] = {};
										}
										if (resources[label.locale].translation.e[entity.schema][entity.name] == null) {
											resources[label.locale].translation.e[entity.schema][entity.name] = {};
										}
										if (resources[label.locale].translation.e[entity.schema][entity.name].questions == null) {
											resources[label.locale].translation.e[entity.schema][entity.name].questions = {};
										}
										resources[label.locale].translation.e[entity.schema][entity.name].questions["" + question.name] = label.label;
									});
								}
							});
						}
						
						if (entity.directReferences != null) {
							entity.directReferences.forEach(reference => {
								if (reference.attributes != null) {
									localModel.entities[entity.schema + "." + entity.name].attributes[reference.referencedKey.entity.name + "Via" + reference.name.substr(0, 1).toUpperCase() + reference.name.substr(1)] = {
										additionalFilter: reference.additionalFilter,
										list: reference.list,
										visible: reference.visible,
										label: reference.label,
										basicFilter: reference.basicFilter,
										linkDisabled: reference.linkDisabled,
										order: reference.order,
										tab: reference.tab,
										group: reference.group,
										xs: reference.xs,
										sm: reference.sm,
									};
								}
							});
						}
						
						if (entity.inverseReferences != null) {
							entity.inverseReferences.forEach(key => {
								if (key.references != null) {
									key.references.forEach(reference => {
										if (reference.attributes != null) {
											localModel.entities[entity.schema + "." + entity.name].attributes[reference.entity.name + "ListVia" + reference.name.substr(0, 1).toUpperCase() + reference.name.substr(1)] = {
												visible: reference.visible,
												filteredWhenEmpty: reference.filteredWhenEmpty,
												order: reference.order,
												tab: reference.tab,
												group: reference.group,
												xs: reference.xs,
												sm: reference.sm,
											};
										}
									});
								}
							});
						}
					});
				}
				
				let model = JSON.parse(json2.data.model);
				
				if (model.entities != null) {
					Object.values(model.entities).forEach(entity => {
						if (entity.references != null) {
							Object.values(entity.references).forEach(reference => {
								reference.referenceAttributeName = reference.referencedKey.entityName.split(".")[1] + "Via" + reference.name.substring(0, 1).toUpperCase() + reference.name.substring(1);
								reference.incomingReferenceAttributeName = reference.entityName.split(".")[1] + "ListVia" + reference.name.substring(0, 1).toUpperCase() + reference.name.substring(1);
							});
						}
					});
				}
				
				let errorMessages = [];
				if (json3.data != null && json3.data.errorMessages != null) {
					errorMessages = json3.data.errorMessages;
				
					json3.data.errorMessages.forEach(errorMessage => {
						if (errorMessage.labels != null) {
							errorMessage.labels.forEach(label => {
								if (resources[label.locale] == null) {
									resources[label.locale] = {};
								}
								if (resources[label.locale].translation == null) {
									resources[label.locale].translation = {};
								}
								if (resources[label.locale].translation.errorMessages == null) {
									resources[label.locale].translation.errorMessages = {};
								}
								resources[label.locale].translation.errorMessages[errorMessage.name] = {
									v: {}
								};
								resources[label.locale].translation.errorMessages[errorMessage.name] = label.message;
							});
						}
					});
				}
				
				//console.log("Model:");
				//console.log(model);
				//console.log("Local model:");
				//console.log(localModel);
				
				context.setState({
					username: model.username,
					accessToken: accessToken,
					model: model,
					localModel: localModel,
					resources: resources,
					errorMessages: errorMessages,
				});
				context.hideActivityIndicator();
			});
		}
	});
}

export async function refreshPersonalization(theme, baseUrl) {
	const query = '{ '
		+ '   personalization: Models_PersonalizationList( '
		+ '     where: {enabled: {EQ: true}} '
		+ '     limit: 1 '
		+ '   ) { '
		+ '     id '
		+ '     primaryColor '
		+ '     secondaryColor '
		+ '     enableUserRegistration '
		+ '     enableCaptcha '
		+ '     showLogoInMenu '
		+ '     showLogoInHeader '
		+ '     supportedLanguages '
		+ '     customerLogo { '
		+ '       oid '
		+ '     } '
		+ '     titles: PersonalizationTitleListViaContainer { '
		+ '       locale '
		+ '       title '
		+ '     } '
		+ '   } '
		+ '   iam: Models_IAMList { '
		+ '     publicKey '
		+ '   } '
		+ '}';
	
	const variables = {
	};
	
	let request = JSON.stringify({query: query, variables: variables});
	
	await fetch(baseUrl + "/graphql", {
		method: "POST",
		body: request
	})
	.then(response => response.json())
	.then(json => {
		if (json != null
				&& json.errors != null
				&& json.errors[0] != null
				&& json.errors[0].message == "SessionTimeout") {
			document.location = '/login';
		}
		
		if (json.data != null
				&& json.data.iam != null
				&& json.data.iam.length > 0) {
			let iam = json.data.iam[0];
			theme.enableIAM = iam.publicKey != null && iam.publicKey.trim() != "";
		}
		
		if (json.data != null 
				&& json.data.personalization != null
				&& json.data.personalization.length > 0) {

			let personalization = json.data.personalization[0];
			
			if (personalization.supportedLanguages != null) {
				theme.supportedLanguages = personalization.supportedLanguages;
			}
			else {
				theme.supportedLanguages = ["en_US"];
			}

			if (personalization.primaryColor != null) {
				theme.palette.primary.main = personalization.primaryColor;
				theme.palette.primary.light = tinycolor(personalization.primaryColor).lighten(50).toString();
				theme.palette.primary.dark = tinycolor(personalization.primaryColor).darken(50).toString();
				theme.palette.primary.contrastText = theme.palette.getContrastText(personalization.primaryColor);
			}
			else {
				theme.palette.primary.main = "#0000FF";
				theme.palette.primary.light = tinycolor("#0000FF").lighten(50).toString();
				theme.palette.primary.dark = tinycolor("#0000FF").darken(50).toString();
				theme.palette.primary.contrastText = theme.palette.getContrastText("#0000FF");
			}
			
			if (personalization.secondaryColor != null) {
				theme.palette.secondary.main = personalization.secondaryColor;
				theme.palette.secondary.light = tinycolor(personalization.secondaryColor).lighten(50).toString();
				theme.palette.secondary.dark = tinycolor(personalization.secondaryColor).darken(50).toString();
				theme.palette.secondary.contrastText = theme.palette.getContrastText(personalization.secondaryColor);
			}
			else {
				theme.palette.secondary.main = "#211A1D";
				theme.palette.secondary.light = tinycolor("#211A1D").lighten(50).toString();
				theme.palette.secondary.dark = tinycolor("#211A1D").darken(50).toString();
				theme.palette.secondary.contrastText = theme.palette.getContrastText("#211A1D");
			}
			
			if (personalization.customerLogo != null) {
				theme.customerLogoImageSrc = baseUrl + "/wopi/files/Models/Personalization/" + personalization.id + "/customerLogo/contents";
			}
			else {
				theme.customerLogoImageSrc = baseUrl + "/logo.png";
			}

			if (personalization.enableUserRegistration != null) {
				theme.enableUserRegistration = personalization.enableUserRegistration;
			}
			else {
				theme.enableUserRegistration = false;
			}

			if (personalization.enableCaptcha != null) {
				theme.enableCaptcha = personalization.enableCaptcha;
			}
			else {
				theme.enableCaptcha = false;
			}

			if (personalization.showLogoInMenu != null) {
				theme.showLogoInMenu = personalization.showLogoInMenu;
			}
			else {
				theme.showLogoInMenu = false;
			}

			if (personalization.showLogoInHeader != null) {
				theme.showLogoInHeader = personalization.showLogoInHeader;
			}
			else {
				theme.showLogoInHeader = true;
			}

			theme.titles = personalization.titles;
		}
		else {
			theme.supportedLanguages = ["en_US"];
			theme.palette.primary.main = "#0000FF";
			theme.palette.primary.light = tinycolor("#0000FF").lighten(50).toString();
			theme.palette.primary.dark = tinycolor("#0000FF").darken(50).toString();
			theme.palette.primary.contrastText = theme.palette.getContrastText("#0000FF");
			theme.palette.secondary.main = "#211A1D";
			theme.palette.secondary.light = tinycolor("#211A1D").lighten(50).toString();
			theme.palette.secondary.dark = tinycolor("#211A1D").darken(50).toString();
			theme.palette.secondary.contrastText = theme.palette.getContrastText("#211A1D");
			theme.customerLogoImageSrc = baseUrl + "/logo.png";
			theme.enableUserRegistration = false;
			theme.enableCaptcha = false;
			theme.showLogoInMenu = false;
			theme.showLogoInHeader = true;
		}
		theme.palette.type = 'dark';
	});
};
