
export const localModel = {
	"entities": {
		"Models.CustomType": {
			"menu": false,
			"icon": "receipt",
			"attributes": {
				"schema": {
					"order": 1,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"name": {
					"order": 2,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"documentation": {
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
					"order": 3,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
				"CustomTypeAttributeListViaContainer": {
					"order": 4,
				},
				"CustomTypeAttributeListViaCustomType": {
					"visible": false,
				},
				"EntityAttributeListViaCustomType": {
					"visible": false,
				},
				"CustomTypeLabelListViaContainer": {
					"order": 5,
				}
			}
		},
		
		"Models.CustomTypeAttribute": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"CustomTypeViaContainer": {
					"order": 1,
					"label": true,
				},
				"name": {
					"order": 2,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"type": {
					"order": 4,
					"xs": 12,
					"sm": 12,
				},
				"isArray": {
					"order": 11,
				},
				"customType": {
					"visible": false,
					"list": false,
				},
				"CustomTypeViaCustomType": {
					"order": 5,
					"list": false,
				},
				"enumType": {
					"visible": false,
					"list": false,
				},
				"EnumTypeViaEnumType": {
					"order": 6,
					"list": false,
				},
				"length": {
					"order": 15,
					"list": false,
					"min": 0,
				},
				"precision": {
					"order": 17,
					"list": false,
					"min": 1,
					"max": 1000,
				},
				"scale": {
					"order": 18,
					"list": false,
					"min": 0,
					"max": 1000,
				},
				"documentation": {
					"order": 12,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.CustomTypeAttributeLabel": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"CustomTypeAttributeViaContainer": {
					"order": 1,
					"label": true,
				},
				"locale": {
					"order": 2,
					"label": true,
					"defaultValue": "es_ES",
				},
				"label": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.CustomTypeLabel": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"CustomTypeViaContainer": {
					"order": 1,
					"label": true,
				},
				"locale": {
					"order": 2,
					"defaultValue": "es_ES",
				},
				"label": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.Entity": {
			"order": 1,
			"menu": true,
			"icon": "description",
			"tabs": {
				"1": {
					"id": 1,
					"name": "main",
					"order": 1,
				},
				"2": {
					"id": 2,
					"name": "attributes",
					"order": 2,
				},
				"3": {
					"id": 3,
					"name": "indicesAndReferences",
					"order": 3,
				},
				"4": {
					"id": 4,
					"name": "actions",
					"order": 4,
				},
				"5": {
					"id": 5,
					"name": "intelligence",
					"order": 5,
				},
				"6": {
					"id": 6,
					"name": "ui",
					"order": 6,
				},
				"7": {
					"id": 7,
					"name": "questions",
					"order": 7,
				},
			},
			"attributes": {
				"schema": {
					"order": 1,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
					"tab": 1,
				},
				"name": {
					"order": 2,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
					"tab": 1,
				},
				"language": {
					"order": 3,
					"tab": 1,
					"defaultValue": "es_ES",
					"lastInRow": true,
				},
				"menu": {
					"order": 5,
					"lastInRow": true,
					"tab": 1,
					"defaultValue": "true",
				},
				"order": {
					"order": 6,
					"min": 1,
					"max": 1000,
					"tab": 1,
				},
				"icon": {
					"order": 7,
					"list": false,
					"icon": true,
					"tab": 1,
				},
				"isExportToCSVEnabled": {
					"order": 8,
					"lastInRow": true,
					"tab": 1,
					"defaultValue": "true",
				},
				"documentation": {
					"order": 4,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
					"tab": 1,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"oid": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
				"EntityAttributeListViaContainer": {
					"order": 8,
					"tab": 2,
				},
				"QuestionListViaContainer": {
					"order": 17,
					"tab": 7,
				},
				"EntityTabListViaContainer": {
					"order": 9,
					"tab": 6,
				},
				"EntityGroupListViaContainer": {
					"order": 10,
					"tab": 6,
				},
				"EntityKeyListViaContainer": {
					"order": 11,
					"tab": 3,
				},
				"EntityReferenceListViaContainer": {
					"order": 12,
					"tab": 3,
				},
				"EntityTriggerListViaContainer": {
					"order": 13,
					"tab": 4,
				},
				"EntityCustomTriggerListViaContainer": {
					"order": 14,
					"tab": 4,
				},
				"EntityLabelListViaContainer": {
					"order": 15,
					"tab": 1,
				},
				"IntelligenceModelListViaEntity": {
					"order": 16,
					"tab": 5,
				},
			}
		},
		
		"Models.EntityAttribute": {
			"tabs": {
				"1": {
					"id": 1,
					"name": "main",
					"order": 1,
				},
				"2": {
					"id": 2,
					"name": "ui",
					"order": 2,
				},
				"3": {
					"id": 3,
					"name": "specific",
					"order": 3,
				},
			},
			"groups": {
				"1": {
					"id": 1,
					"name": "text",
					"order": 1,
				},
				"3": {
					"id": 3,
					"name": "numeric",
					"order": 2,
				},
				"5": {
					"id": 5,
					"name": "date",
					"order": 3,
				},
				"9": {
					"id": 9,
					"name": "point",
					"order": 5,
				},
				"10": {
					"id": 10,
					"name": "document",
					"order": 4,
				},
				"12": {
					"id": 12,
					"name": "barcode",
					"order": 6,
				},
				"13": {
					"id": 13,
					"name": "mrz",
					"order": 7,
				},
			},
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
					"tab": 1,
				},
				"EntityViaContainer": {
					"order": 1,
					"label": true,
					"tab": 1,
				},
				"name": {
					"order": 2,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
					"tab": 1,
				},
				"type": {
					"order": 3,
					"tab": 1,
				},
				"isArray": {
					"order": 4,
					"tab": 3,
					"group": 1,
				},
				"isPassword": {
					"order": 5,
					"tab": 3,
					"group": 1,
				},
				"isEventStart": {
					"order": 18,
					"tab": 3,
					"group": 5,
				},
				"isEventEnd": {
					"order": 19,
					"tab": 3,
					"group": 5,
				},
				"isPointInMap": {
					"order": 20,
					"tab": 3,
					"group": 9,
				},
				"isImageInGallery": {
					"order": 2,
					"tab": 3,
					"group": 10,
				},
				"isComputed": {
					"order": 22,
					"tab": 1,
				},
				"isEncrypted": {
					"order": 23,
					"list": false,
					"visible": false,
					"tab": 1,
				},
				"indexDocuments": {
					"order": 24,
					"list": false,
					"visible": false,
					"tab": 1,
				},
				"browseZip": {
					"order": 3,
					"tab": 3,
					"group": 10,
				},
				"defaultValue": {
					"order": 4,
					"list": false,
					"tab": 1,
				},
				"isRequired": {
					"order": 7,
					"tab": 1,
				},
				"customType": {
					"visible": false,
					"list": false,
					"tab": 1,
				},
				"CustomTypeViaCustomType": {
					"order": 5,
					"list": false,
					"visible": false,
					"tab": 1,
				},
				"enumType": {
					"visible": false,
					"list": false,
					"lastInRow": true,
					"tab": 3,
					"group": 1,
				},
				"EnumTypeViaEnumType": {
					"order": 3,
					"list": false,
					"tab": 3,
					"group": 1,
				},
				"length": {
					"order": 1,
					"list": false,
					"min": 0,
					"tab": 3,
					"group": 1,
					"defaultValue": "100",
				},
				"pattern": {
					"order": 2,
					"list": false,
					"tab": 3,
					"group": 1,
				},
				"precision": {
					"order": 30,
					"list": false,
					"min": 1,
					"max": 1000,
					"tab": 3,
					"group": 3,
				},
				"scale": {
					"order": 31,
					"list": false,
					"min": 0,
					"max": 1000,
					"tab": 3,
					"group": 3,
				},
				"isLabel": {
					"order": 8,
					"tab": 1,
				},
				"isList": {
					"order": 9,
					"tab": 1,
				},
				"isVisible": {
					"order": 10,
					"tab": 1,
					"defaultValue": "true",
				},
				"isBasicFilter": {
					"order": 11,
					"tab": 1,
				},
				"isTextFilter": {
					"order": 12,
					"tab": 1,
				},
				"isTextSearch": {
					"order": 13,
					"tab": 1,
				},
				"variants": {
					"order": 27,
					"xs": 12,
					"sm": 12,
					"list": false,
					"tab": 2,
				},
				"isVariantSelector": {
					"order": 28,
					"list": false,
					"tab": 2,
					"lastInRow": true,
				},
				"isLastInRow": {
					"order": 29,
					"tab": 2,
				},
				"isHidden": {
					"order": 30,
					"tab": 2,
				},
				"isIcon": {
					"order": 13,
					"tab": 3,
					"group": 1,
				},
				"isColor": {
					"order": 14,
					"tab": 3,
					"group": 1,
				},
				"tab": {
					"visible": false,
					"list": false,
					"tab": 2,
				},
				"EntityTabViaTab": {
					"order": 23,
					"additionalFilter": "container: {EQ: $container}",
					"tab": 2,
				},
				"group": {
					"visible": false,
					"list": false,
					"tab": 2,
				},
				"EntityGroupViaGroup": {
					"order": 24,
					"additionalFilter": "container: {EQ: $container}",
					"tab": 2,
				},
				"order": {
					"order": 5,
					"min": 1,
					"max": 1000,
					"lastInRow": true,
					"tab": 2,
				},
				"xs": {
					"order": 25,
					"list": false,
					"min": 1,
					"max": 12,
					"tab": 2,
				},
				"sm": {
					"order": 26,
					"list": false,
					"min": 1,
					"max": 12,
					"tab": 2,
				},
				"min": {
					"order": 32,
					"list": false,
					"step": 0.000001,
					"tab": 3,
					"group": 3,
				},
				"max": {
					"order": 33,
					"list": false,
					"step": 0.000001,
					"tab": 3,
					"group": 3,
				},
				"step": {
					"order": 34,
					"list": false,
					"min": 0.000001,
					"step": 0.000001,
					"tab": 3,
					"group": 3,
				},
				"prefix": {
					"order": 35,
					"list": false,
					"tab": 3,
					"group": 3,
					"sm": 3,
				},
				"suffix": {
					"order": 36,
					"list": false,
					"tab": 3,
					"group": 3,
					"sm": 3,
				},
				"isMultiline": {
					"order": 29,
					"list": false,
					"tab": 3,
					"group": 1,
				},
				"isAction": {
					"order": 30,
					"list": false,
					"tab": 3,
					"group": 1,
				},
				"maxFiles": {
					"order": 36,
					"list": false,
					"visible": false,
					"min": 1,
					"tab": 3,
					"group": 10,
				},
				"acceptedFileTypes": {
					"order": 1,
					"xs": 12,
					"sm": 12,
					"list": false,
					"tab": 3,
					"group": 10,
				},
				"barcodeType": {
					"order": 37,
					"label": true,
					"tab": 3,
					"group": 12,
				},
				"mrzField": {
					"order": 38,
					"label": false,
					"tab": 3,
					"group": 13,
				},
				"documentation": {
					"order": 22,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
					"tab": 1,
				},
				"search": {
					"visible": false,
					"list": false,
					"tab": 1,
				},
				"id": {
					"visible": false,
					"list": false,
					"tab": 1,
				},
				"attnum": {
					"visible": false,
					"list": false,
					"tab": 1,
				},
				"timestamp": {
					"visible": false,
					"list": false,
					"tab": 1,
				},
				"EntityKeyAttributeListViaAttribute": {
					"visible": false,
					"tab": 1,
				},
				"EntityReferenceAttributeListViaAttribute": {
					"visible": false,
					"tab": 1,
				},
				"IntelligenceModelListViaLabel": {
					"visible": false,
					"tab": 1,
				},
				"IntelligenceFeatureListViaAttribute": {
					"visible": false,
					"tab": 1,
				},
				"EntityAttributeLabelListViaContainer": {
					"order": 39,
					"tab": 1,
				},
			}
		},
		
		"Models.EntityAttributeLabel": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EntityAttributeViaContainer": {
					"order": 1,
					"label": true,
				},
				"locale": {
					"order": 2,
					"label": true,
					"defaultValue": "es_ES",
				},
				"label": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.Question": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EntityViaContainer": {
					"order": 1,
					"label": true,
				},
				"name": {
					"order": 2,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"questionId": {
					"order": 3,
					"list": true,
					"min": 0,
				},
				"order": {
					"order": 4,
					"min": 1,
					"max": 1000,
					"lastInRow": true,
				},
				"isList": {
					"order": 5,
					"list": true,
				},
				"isVisible": {
					"order": 6,
					"list": true,
				},
				"useIds": {
					"order": 7,
					"list": true,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
				"QuestionLabelListViaContainer": {
					"order": 10,
				},
			}
		},
		
		"Models.QuestionLabel": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"QuestionViaContainer": {
					"order": 1,
					"label": true,
				},
				"locale": {
					"order": 2,
					"label": true,
					"defaultValue": "es_ES",
				},
				"label": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.Dashboard": {
			"order": 6,
			"menu": true,
			"icon": "dashboard",
			"attributes": {
				"name": {
					"order": 1,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"dashboardId": {
					"order": 2,
					"list": true,
					"min": 0,
				},
				"order": {
					"order": 3,
					"min": 1,
					"max": 1000,
					"lastInRow": true,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
				"DashboardLabelListViaContainer": {
					"order": 10,
				},
			}
		},
		
		"Models.DashboardLabel": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"DashboardViaContainer": {
					"order": 1,
					"label": true,
				},
				"locale": {
					"order": 2,
					"label": true,
					"defaultValue": "es_ES",
				},
				"label": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.DashboardPermission": {
			"attributes": {
				"role": {
					"visible": false,
					"list": false,
				},
				"RoleViaRole": {
					"order": 1,
					"label": true,
				},
				"dashboard": {
					"visible": false,
					"list": false,
				},
				"DashboardViaDashboard": {
					"order": 2,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.EntityKey": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EntityViaContainer": {
					"order": 1,
					"label": true,
				},
				"schema": {
					"order": 2,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"name": {
					"order": 3,
					"label": true,
					"xs": 12,
					"sm": 12,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"isUnique": {
					"order": 4,
				},
				"isTextSearch": {
					"order": 5,
				},
				"isPrimaryKey": {
					"order": 6,
				},
				"documentation": {
					"order": 7,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
				"EntityKeyAttributeListViaContainer": {
					"order": 8,
				},
				"EntityReferenceListViaReferencedKey": {
					"visible": false,
				}
			}
		},
		
		"Models.EntityKeyAttribute": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EntityKeyViaContainer": {
					"order": 1,
					"label": true,
				},
				"attribute": {
					"visible": false,
					"list": false,
				},
				"EntityAttributeViaAttribute": {
					"order": 2,
					"label": true,
					//"additionalFilter": "container: {EQ: $container.container}"
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.EntityLabel": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EntityViaContainer": {
					"order": 1,
					"label": true,
				},
				"locale": {
					"order": 2,
					"label": true,
					"defaultValue": "es_ES",
				},
				"label": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"label": true,
				},
				"pluralLabel": {
					"order": 4,
					"xs": 12,
					"sm": 12,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.EntityReference": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EntityViaContainer": {
					"order": 1,
					"label": true,
				},
				"name": {
					"order": 2,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"referencedKey": {
					"visible": false,
					"list": false,
				},
				"EntityKeyViaReferencedKey": {
					"order": 3,
				},
				"additionalFilter": {
					"order": 4,
				},
				"documentation": {
					"order": 5,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
				},
				"isList": {
					"order": 6,
					"xs": 12,
					"sm": 12,
				},
				"isVisible": {
					"order": 7,
				},
				"listIsVisible": {
					"order": 8,
				},
				"isLabel": {
					"order": 9,
				},
				"listIsFilteredWhenEmpty": {
					"order": 10,
				},
				"isBasicFilter": {
					"order": 11,
					"lastInRow": false,
				},
				"isCascadeDelete": {
					"order": 12,
					"lastInRow": false,
				},
				"isCascadeSetNull": {
					"order": 14,
				},
				"order": {
					"order": 15,
				},
				"listOrder": {
					"order": 16,
				},
				"tab": {
					"visible": false,
					"list": false,
				},
				"EntityTabViaTab": {
					"order": 17,
					"additionalFilter": "container: {EQ: $container}",
				},
				"listTab": {
					"visible": false,
					"list": false,
				},
				"isLinkDisabled": {
					"order": 13,
				},
				"EntityTabViaListTab": {
					"order": 18,
				},
				"group": {
					"visible": false,
					"list": false,
				},
				"EntityGroupViaGroup": {
					"order": 19,
					"additionalFilter": "container: {EQ: $container}",
				},
				"listGroup": {
					"visible": false,
					"list": false,
				},
				"EntityGroupViaListGroup": {
					"order": 20,
				},
				"xs": {
					"order": 21,
					"list": false,
				},
				"listXs": {
					"order": 22,
					"list": false,
				},
				"sm": {
					"order": 23,
					"list": false,
				},
				"listSm": {
					"order": 24,
					"list": false,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},				
				"EntityReferenceAttributeListViaContainer": {
					"order": 25,
				},
			}
		},
		
		"Models.EntityReferenceAttribute": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EntityReferenceViaContainer": {
					"order": 1,
					"label": true,
				},
				"attribute": {
					"visible": false,
					"list": false,
				},
				"EntityAttributeViaAttribute": {
					"order": 2,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.IntelligenceModel": {
			"order": 6,
			"menu": false,
			"icon": "memory",
			"tabs": {
				"1": {
					"id": 1,
					"name": "basic",
					"order": 1,
				},
				"2": {
					"id": 2,
					"name": "advanced",
					"order": 2,
				},
				"3": {
					"id": 3,
					"name": "training",
					"order": 3,
				},
			},
			"attributes": {
				"entity": {
					"visible": false,
					"list": false,
					"tab": 1,
				},
				"EntityViaEntity": {
					"order": 1,
					"label": true,
					"tab": 1,
				},
				"name": {
					"order": 2,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
					"tab": 1,
				},
				"problemType": {
					"order": 3,
					"tab": 1,
				},
				"trainingPeriodicity": {
					"visible": false,
					"list": false,
					"tab": 1,
				},
				"maxTrainingMinutes": {
					"order": 4,
					"defaultValue": "10",
					"min": 1,
					"max": 1000,
					"list": false,
					"lastInRow": true,
					"tab": 1,
				},
				"targetAttribute": {
					"visible": false,
					"list": false,
					"tab": 1,
				},
				"EntityAttributeViaTargetAttribute": {
					"order": 5,
					"tab": 1,
				},
				"outputTargetAttribute": {
					"visible": false,
					"list": false,
					"tab": 2,
				},
				"EntityAttributeViaOutputTargetAttribute": {
					"order": 100,
					"tab": 2,
				},
				"label": {
					"visible": false,
					"list": false,
					"tab": 1,
				},
				"EntityAttributeViaLabel": {
					"order": 5,
					"tab": 1,
				},
				"IntelligenceFeatureListViaContainer": {
					"order": 8,
					"tab": 1,
				},
				"IntelligenceMetricListViaContainer": {
					"order": 10,
					"tab": 2,
				},
				"lastTrainedAt": {
					"order": 4,
					"computed": true,
					"tab": 3,
				},
				"trainAsap": {
					"order": 1,
					"list": false,
					"tab": 3,
				},
				"training": {
					"order": 2,
					"computed": true,
					"list": false,
					"tab": 3,
				},
				"trainingResult": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
					"tab": 3,
				},
				"model": {
					"list": false,
					"visible": false,
					"tab": 1,
				},
				"objective": {
					"order": 1,
					"list": false,
					"tab": 2,
				},
				"objectiveDirection": {
					"order": 2,
					"list": false,
					"tab": 2,
				},
				"loss": {
					"order": 5,
					"list": false,
					"step": 0.000001,
					"computed": true,
					"tab": 3,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.IntelligenceFeature": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"IntelligenceModelViaContainer": {
					"order": 1,
					"label": true,
				},
				"attribute": {
					"visible": false,
					"list": false,
				},
				"EntityAttributeViaAttribute": {
					"order": 2,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.IntelligenceMetric": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"IntelligenceModelViaContainer": {
					"order": 1,
					"label": true,
				},
				"metric": {
					"order": 2,
				},
				"value": {
					"order": 3,
					"step": 0.000001,
					"computed": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.EnumType": {
			"order": 3,
			"menu": true,
			"icon": "list",
			"attributes": {
				"schema": {
					"order": 1,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"name": {
					"order": 2,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"values": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"documentation": {
					"order": 4,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"oid": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
				"EntityAttributeListViaEnumType": {
					"visible": false,
				},
				"CustomTypeAttributeListViaEnumType": {
					"visible": false,
				},
				"EnumTypeLabelListViaContainer": {
					"order": 5,
				},
			}
		},
		
		"Models.EnumTypeLabel": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EnumTypeViaContainer": {
					"order": 1,
					"label": true,
				},
				"locale": {
					"order": 2,
					"label": true,
					"defaultValue": "es_ES",
				},
				"labels": {
					"order": 3,
					"xs": 12,
					"sm": 12,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},

		"Models.EntityTab": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EntityViaContainer": {
					"order": 1,
					"label": true,
				},
				"name": {
					"order": 2,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"order": {
					"order": 3,
					"min": 1,
					"max": 1000,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
				"EntityTabLabelListViaContainer": {
					"order": 24,
				},
			}
		},
		
		"Models.EntityTabLabel": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EntityTabViaContainer": {
					"order": 1,
					"label": true,
				},
				"locale": {
					"order": 2,
					"label": true,
					"defaultValue": "es_ES",
				},
				"label": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.EntityGroup": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EntityViaContainer": {
					"order": 1,
					"label": true,
				},
				"name": {
					"order": 2,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"order": {
					"order": 3,
					"min": 1,
					"max": 1000,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
				"EntityGroupLabelListViaContainer": {
					"order": 24,
				},
			}
		},
		
		"Models.EntityGroupLabel": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EntityGroupViaContainer": {
					"order": 1,
					"label": true,
				},
				"locale": {
					"order": 2,
					"label": true,
					"defaultValue": "es_ES",
				},
				"label": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.Role": {
			"order": 4,
			"menu": true,
			"icon": "security",
			"tabs": {
				"1": {
					"id": 1,
					"name": "main",
					"order": 1,
				},
				"2": {
					"id": 2,
					"name": "data",
					"order": 2,
				},
				"3": {
					"id": 3,
					"name": "others",
					"order": 3,
				},
			},
			"attributes": {
				"name": {
					"order": 1,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
					"tab": 1,
				},
				"defaultEntity": {
					"visible": false,
					"list": false,
					"tab": 1,
				},
				"EntityViaDefaultEntity": {
					"order": 2,
					"tab": 1,
				},
				"documentation": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
					"tab": 1,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"oid": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
				"EntityPermissionListViaRole": {
					"order": 4,
					"tab": 2,
				},
				"EntityAttributePermissionListViaRole": {
					"order": 5,
					"tab": 2,
				},
				"RowLevelPermissionListViaRole": { 
					"order": 6, 
					"tab": 2,
				},
				"DashboardPermissionListViaRole": {
					"order": 7,
					"tab": 3,
				},
				"ExternalEntityPermissionListViaRole": {
					"order": 8,
					"tab": 3,
				},
				"NetworkPermissionListViaRole": {
					"order": 9,
					"tab": 3,
				},
				"SchemaPermissionListViaRole": {
					"order": 10,
					"tab": 3,
				},
				"CustomTriggerPermissionListViaRole": {
					"order": 11,
					"tab": 3,
				},
				"UserRoleListViaRole": {
					"order": 12,
					"tab": 1,
				},
			}
		},
		
		"Models.EntityPermission": {
			"attributes": {
				"role": {
					"visible": false,
					"list": false,
				},
				"RoleViaRole": {
					"order": 1,
					"label": true,
				},
				"entity": {
					"visible": false,
					"list": false,
				},
				"EntityViaEntity": {
					"order": 2,
					"label": true,
				},
				"types": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.EntityAttributePermission": {
			"attributes": {
				"role": {
					"visible": false,
					"list": false,
				},
				"RoleViaRole": {
					"order": 1,
					"label": true,
				},
				"attribute": {
					"visible": false,
					"list": false,
				},
				"EntityAttributeViaAttribute": {
					"order": 2,
					"label": true,
				},
				"types": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.RowLevelPermission": {
			"attributes": {
				"entity": {
					"visible": false,
					"list": false,
				},
				"EntityViaEntity": {
					"order": 1,
					"label": true,
				},
				"as": {
					"order": 2,
					"defaultValue": "PERMISSIVE",
				},
				"role": {
					"visible": false,
					"list": false,
				},
				"RoleViaRole": {
					"order": 3,
					"label": true,
				},
				"for": {
					"order": 4,
					"defaultValue": "ALL",
				},
				"name": {
					"order": 5,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"expression": {
					"order": 6,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
				},
				"checkExpression": {
					"order": 7,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.ExternalEntityPermission": {
			"attributes": {
				"role": {
					"visible": false,
					"list": false,
				},
				"RoleViaRole": {
					"order": 1,
					"label": true,
				},
				"externalEntity": {
					"visible": false,
					"list": false,
				},
				"ExternalEntityViaExternalEntity": {
					"order": 2,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.CustomTriggerPermission": {
			"attributes": {
				"role": {
					"visible": false,
					"list": false,
				},
				"RoleViaRole": {
					"order": 1,
					"label": true,
				},
				"customTrigger": {
					"visible": false,
					"list": false,
				},
				"EntityCustomTriggerViaCustomTrigger": {
					"order": 2,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.User": {
			"order": 5,
			"menu": true,
			"icon": "person",
			"tabs": {
				"1": {
					"id": 1,
					"name": "main",
					"order": 1,
				},
				/*
				"2": {
					"id": 2,
					"name": "phoneCalls",
					"order": 2,
				},
				*/
				"3": {
					"id": 3,
					"name": "systemAccesses",
					"order": 3,
				},
				"4": {
					"id": 4,
					"name": "twoFactorAuthentication",
					"order": 2,
				},
			},
			"attributes": {
				"username": {
					"order": 1,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
					"tab": 1,
				},
				"password": {
					"order": 2,
					"list": false,
					"password": true,
					"pattern": "^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\@\#\$\!\%\*\?\&\_\-])[A-Za-z0-9\@\#\$\!\%\*\?\&\_\-]{8,}$",
					"tab": 1,
				},
				"email": {
					"order": 3,
					"lastInRow": true,
					"list": false,
					"visible": false,
					"tab": 1,
				},
				"emailValidationCode": {
					"order": 4,
					"list": false,
					"visible": false,
					"tab": 1,
				},
				"passwordChangeValidationCode": {
					"order": 5,
					"list": false,
					"visible": false,
					"lastInRow": true,
					"tab": 1,
				},
				"emailValidated": {
					"order": 6,
					"lastInRow": true,
					"list": false,
					"visible": false,
					"tab": 1,
				},
				"isAdmin": {
					"order": 7,
					"tab": 1,
				},
				"agreementAccepted": {
					"order": 8,
					"lastInRow": true,
					"tab": 1,
				},
				"phoneIpAddress": {
					"order": 1,
					"list": false,
					"visible": false,
					"tab": 2,
				},
				"phonePassword": {
					"order": 2,
					"list": false,
					"visible": false,
					"password": true,
					"pattern": "^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\@\#\$\!\%\*\?\&\_\-])[A-Za-z0-9\@\#\$\!\%\*\?\&\_\-]{8,}$",
					"tab": 2,
				},
				"phoneRedirectAttribute": {
					"list": false,
					"visible": false,
				},
				"enable2FA": {
					"list": true,
					"order": 10,
					"lastInRow": true,
					"tab": 4,
				},
				"twoFactorAuthenticationKey": {
					"list": false,
					"order": 11,
					"lastInRow": true,
					"tab": 4,
					"type": "BARCODE",
					"barcodeType": "QR_CODE",
					"sm": 12,
				},
				"EntityAttributeViaPhoneRedirectAttribute": {
					"order": 3,
					"list": false,
					"visible": false,
					"tab": 2,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"oid": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
				"UserRoleListViaUser": {
					"order": 9,
					"tab": 1,
				},
				"UserAccessListViaUser": {
					"order": 9,
					"tab": 3,
				},
			}
		},
		
		"Models.UserRole": {
			"attributes": {
				"role": {
					"visible": false,
					"list": false,
				},
				"RoleViaRole": {
					"order": 1,
					"label": true,
				},
				"user": {
					"visible": false,
					"list": false,
				},
				"UserViaUser": {
					"order": 2,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.Function": {
			"order": 2,
			"menu": true,
			"icon": "functions",
			"attributes": {
				"schema": {
					"order": 1,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"name": {
					"order": 2,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"language": {
					"order": 3,
				},
				"contents": {
					"order": 4,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
				},
				"cronExpression": {
					"order": 5,
					"lastInRow": true,
				},
				"isHttpEnabled": {
					"order": 6,
					"lastInRow": true,
				},
				"interceptHttpRequests": {
					"order": 7,
					"lastInRow": true,
				},
				"documentation": {
					"order": 8,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
				},
				"jobid": {
					"visible": false,
					"list": false,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
				"EntityTriggerListViaFunction": {
					"order": 9,
				},
				"EntityCustomTriggerListViaFunction": {
					"order": 10,
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.EntityTrigger": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EntityViaContainer": {
					"order": 1,
					"label": true,
				},
				"name": {
					"order": 2,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"moment": {
					"order": 3,
					"xs": 12,
					"sm": 12,
				},
				"events": {
					"order": 4,
					"xs": 12,
					"sm": 12,
				},
				"each": {
					"order": 5,
					"xs": 12,
					"sm": 12,
				},
				"function": {
					"visible": false,
					"list": false,
				},
				"FunctionViaFunction": {
					"order": 6,
					"list": false,
				},
				"condition": {
					"order": 7,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.EntityCustomTrigger": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EntityViaContainer": {
					"order": 1,
					"label": true,
				},
				"name": {
					"order": 2,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"function": {
					"visible": false,
					"list": false,
				},
				"FunctionViaFunction": {
					"order": 6,
					"list": false,
				},
				"EntityCustomTriggerLabelListViaContainer": {
					"order": 10,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.EntityCustomTriggerLabel": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"EntityCustomTriggerViaContainer": {
					"order": 1,
					"label": true,
				},
				"locale": {
					"order": 2,
					"label": true,
					"defaultValue": "es_ES",
				},
				"label": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.ExternalEntity": {
			"order": 9,
			"menu": true,
			"icon": "exit_to_app",
			"attributes": {
				"schema": {
					"order": 1,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"name": {
					"order": 2,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"url": {
					"order": 3,
					"sm": 12,
				},
				"menu": {
					"order": 5,
					"lastInRow": true,
				},
				"order": {
					"order": 6,
					"min": 1,
					"max": 1000,
				},
				"icon": {
					"order": 7,
					"list": false,
					"icon": true,
				},
				"documentation": {
					"order": 4,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
				"ExternalEntityLabelListViaContainer": {
					"order": 14,
				},
			}
		},
		
		"Models.WebRedirection": {
			"order": 10,
			"menu": true,
			"icon": "arrow_forward",
			"attributes": {
				"sourcePath": {
					"order": 1,
					"label": true,
				},
				"targetUrl": {
					"order": 2,
				},
				"permanent": {
					"order": 3,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.ExternalEntityLabel": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"ExternalEntityViaContainer": {
					"order": 1,
					"label": true,
				},
				"locale": {
					"order": 2,
					"label": true,
					"defaultValue": "es_ES",
				},
				"label": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"label": true,
				},
				"pluralLabel": {
					"order": 4,
					"xs": 12,
					"sm": 12,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.WebPackage": {
			"order": 8,
			"menu": true,
			"icon": "web",
			"attributes": {
				"name": {
					"order": 1,
					"label": true,
				},
				"type": {
					"order": 2,
				},
				"package": {
					"order": 3,
					"sm": 12,
					"type": "DOCUMENT",
					"acceptedFileTypes": ["application/zip", "application/x-compressed", "application/x-zip-compressed", "multipart/x-zip"],
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.Personalization": {
			"order": 20,
			"menu": true,
			"icon": "settings",
			"tabs": {
				"1": {
					"id": 1,
					"name": "main",
					"order": 1,
				},
				"2": {
					"id": 2,
					"name": "colors",
					"order": 2,
				},
				/*
				"3": {
					"id": 3,
					"name": "email",
					"order": 3,
				},
				"4": {
					"id": 4,
					"name": "userRegistration",
					"order": 4,
				},
				*/
			},
			"attributes": {
				"name": {
					"order": 1,
					"label": true,
					"lastInRow": true,
					"defaultValue": "Default",
					"tab": 1,
				},
				"primaryColor": {
					"order": 1,
					"list": false,
					"pattern": "\#[a-fA-F0-9]{6}",
					"color": true,
					"defaultValue": "#0000FF",
					"tab": 2,
					"lastInRow": true,
				},
				"secondaryColor": {
					"order": 2,
					"list": false,
					"pattern": "\#[a-fA-F0-9]{6}",
					"color": true,
					"defaultValue": "#211A1D",
					"tab": 2,
					"lastInRow": true,
				},
				"supportedLanguages": {
					"order": 4,
					"xs": 12,
					"sm": 12,
					"defaultValue": "es_ES",
					"tab": 1,
				},
				"customerLogo": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"type": "DOCUMENT",
					"acceptedFileTypes": ["image/png", "image/jpeg"],
					"tab": 2,
				},
				"showLogoInMenu": {
					"order": 4,
					"tab": 2,
					"lastInRow": true,
				},
				"showLogoInHeader": {
					"order": 5,
					"defaultValue": "true",
					"tab": 2,
				},
				"documentWatermark": {
					"order": 6,
					"list": false,
					"visible": false,
					"lastInRow": true,
					"tab": 1,
				},
				"PersonalizationTitleListViaContainer": {
					"order": 17,
					"tab": 1,
				},
				"enabled": {
					"order": 19,
					"defaultValue": "true",
					"tab": 1,
				},
				"smtpHost": {
					"order": 9,
					"list": false,
					"visible": false,
					"tab": 3,
				},
				"smtpPort": {
					"order": 10,
					"list": false,
					"visible": false,
					"tab": 3,
				},
				"smtpAuth": {
					"order": 13,
					"lastInRow": true,
					"list": false,
					"visible": false,
					"tab": 3,
				},
				"smtpUsername": {
					"order": 11,
					"list": false,
					"visible": false,
					"tab": 3,
				},
				"smtpPassword": {
					"order": 12,
					"list": false,
					"visible": false,
					"tab": 3,
				},
				"enableUserRegistration": {
					"order": 15,
					"lastInRow": true,
					"list": false,
					"visible": false,
					"tab": 4,
				},
				"defaultUserRole": {
					"visible": false,
					"list": false,
					"lastInRow": true,
				},
				"RoleViaDefaultUserRole": {
					"order": 14,
					"list": false,
					"visible": false,
					"tab": 4,
				},
				"enableCaptcha": {
					"order": 16,
					"lastInRow": true,
					"list": false,
					"visible": false,
					"tab": 4,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.PersonalizationTitle": {
			"menu": false,
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"PersonalizationViaContainer": {
					"order": 1,
					"label": true,
				},
				"locale": {
					"order": 2,
					"label": true,
					"defaultValue": "es_ES",
				},
				"title": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.Report": {
			"order": 11,
			"menu": true,
			"icon": "receipt",
			"attributes": {
				"name": {
					"order": 1,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"template": {
					"order": 2,
					"xs": 12,
					"sm": 12,
					"type": "DOCUMENT",
					"acceptedFileTypes": ["application/vnd.oasis.opendocument.text", "application/vnd.oasis.opendocument.spreadsheet"],
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
				"ReportTriggerListViaContainer": {
					"order": 8,
				},
			}
		},
		
		"Models.ReportTrigger": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"ReportViaContainer": {
					"order": 1,
					"label": true,
				},
				"name": {
					"order": 2,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"graphql": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
				},
				"triggerEntity": {
					"visible": false,
					"list": false,
				},
				"EntityViaTriggerEntity": {
					"order": 4,
					"list": false,
				},
				"targetAttribute": {
					"visible": false,
					"list": false,
				},
				"EntityAttributeViaTargetAttribute": {
					"order": 5,
					"list": false,
				},
				"targetEntityExpression": {
					"order": 6,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.NetworkPermission": {
			"attributes": {
				"role": {
					"visible": false,
					"list": false,
					"lastInRow": true,
				},
				"RoleViaRole": {
					"order": 1,
					"label": true,
				},
				"accessType": {
					"order": 2,
					"label": true,
				},
				"order": {
					"order": 3,
					"label": false,
				},
				"cidr": {
					"order": 4,
					"label": true,
					"lastInRow": true,
				},
				"ip": {
					"order": 5,
					"label": true,
				},
				"mask": {
					"order": 6,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.SchemaPermission": {
			"attributes": {
				"role": {
					"visible": false,
					"list": false,
				},
				"RoleViaRole": {
					"order": 1,
					"label": true,
				},
				"schema": {
					"order": 2,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"timestamp": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.UserAccess": {
			"attributes": {
				"user": {
					"visible": false,
					"list": false,
					"lastInRow": true,
				},
				"UserViaUser": {
					"order": 1,
					"label": true,
				},
				"ip": {
					"order": 2,
					"label": true,
				},
				"timestamp": {
					"order": 3,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
			}
		},
		
		"Models.ErrorMessage": {
			"order": 12,
			"menu": true,
			"icon": "message",
			"attributes": {
				"name": {
					"order": 1,
					"label": true,
					"pattern": "[_A-Za-z][_0-9A-Za-z]*",
				},
				"regularExpression": {
					"order": 2,
					"label": false,
				},
				"documentation": {
					"order": 4,
					"xs": 12,
					"sm": 12,
					"multiline": true,
					"list": false,
				},
				"search": {
					"visible": false,
					"list": false,
				},
				"id": {
					"visible": false,
					"list": false,
				},
				"ErrorMessageLabelListViaContainer": {
					"order": 15,
				},
			}
		},
		
		"Models.ErrorMessageLabel": {
			"attributes": {
				"container": {
					"visible": false,
					"list": false,
				},
				"ErrorMessageViaContainer": {
					"order": 1,
					"label": true,
				},
				"locale": {
					"order": 2,
					"label": true,
					"defaultValue": "es_ES",
				},
				"message": {
					"order": 3,
					"xs": 12,
					"sm": 12,
					"label": true,
				},
				"id": {
					"visible": false,
					"list": false,
				},
			}
		},
	},
};
